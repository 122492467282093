import React, { MouseEvent } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../store";
import { fetchAllReports, updateFilterOptions } from "../store/reportsSlice";
import { ResultCounts } from "../types/report";

export const TEST_ID_REPORT_VIEW_TOGGLE = "ReportViewToggle";
export const TEST_ID_REPORT_VIEW_TOGGLE_LINK = "ReportViewToggleLink";

interface ReportViewToggleProps {
  selectedView: ViewOption;
  reportCounts: ResultCounts;
}

export enum ViewOption {
  Inbox = "Inbox",
  Archive = "Archive",
}

const viewOptions: ViewOption[] = [ViewOption.Inbox, ViewOption.Archive];

const ReportViewToggle = ({
  selectedView,
  reportCounts,
}: ReportViewToggleProps): JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = (e: MouseEvent, viewOption: ViewOption): void => {
    e.preventDefault();
    if (viewOption !== selectedView) {
      dispatch(updateFilterOptions({ viewOption }));
      dispatch(fetchAllReports({ showSpinner: true }));
    }
  };

  return (
    <div role="radiogroup" aria-labelledby="viewReportsLabel">
      <div className="label" id="viewReportsLabel">
        View
      </div>
      <div className="tabs is-small is-fullwidth is-toggle is-toggle-rounded">
        <ul>
          {viewOptions.map((option: ViewOption) => {
            const isSelected: boolean = option === selectedView;
            return (
              <li
                className={isSelected ? "is-active" : ""}
                key={option}
                data-testid={TEST_ID_REPORT_VIEW_TOGGLE + option}
              >
                <a
                  href=""
                  role="radio"
                  aria-checked={isSelected}
                  onClick={(e) => handleClick(e, option)}
                  data-testid={TEST_ID_REPORT_VIEW_TOGGLE_LINK + option}
                >
                  {option} ({reportCounts[option]})
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ReportViewToggle;
