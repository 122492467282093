import { invert } from "lodash";
import React, { MouseEvent } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../store";
import { fetchAllReports, updateFilterOptions } from "../store/reportsSlice";

export const TEST_ID_REPORT_SORT_TOGGLE = "ReportSortToggle";
export const TEST_ID_REPORT_SORT_TOGGLE_LINK = "ReportSortToggleLink";

interface ReportSortToggleProps {
  selectedSort: SortOption;
}

export enum SortOption {
  Priority = "Priority",
  ClinicDate = "Clinic date",
}

const sortByOptions: SortOption[] = [SortOption.Priority, SortOption.ClinicDate];

const ReportSortToggle = ({ selectedSort }: ReportSortToggleProps): JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = (e: MouseEvent, sortOption: SortOption): void => {
    e.preventDefault();
    if (sortOption !== selectedSort) {
      dispatch(updateFilterOptions({ sortOption }));
      dispatch(fetchAllReports({ showSpinner: false }));
    }
  };

  return (
    <div role="radiogroup" aria-labelledby="sortReportsLabel">
      <div className="label" id="sortReportsLabel">
        Sort by
      </div>
      <div className="tabs is-small is-fullwidth is-toggle is-toggle-rounded">
        <ul>
          {sortByOptions.map((option) => {
            const isSelected: boolean = option === selectedSort;
            return (
              <li
                className={isSelected ? "is-active" : ""}
                key={option}
                data-testid={TEST_ID_REPORT_SORT_TOGGLE + invert(SortOption)[option]}
              >
                <a
                  href=""
                  role="radio"
                  aria-checked={isSelected}
                  onClick={(e) => handleClick(e, option)}
                  data-testid={
                    TEST_ID_REPORT_SORT_TOGGLE_LINK + invert(SortOption)[option]
                  }
                >
                  {option}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ReportSortToggle;
