import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { GroupOption } from "../components/ReportGroupFilter";
import { SortOption } from "../components/ReportSortToggle";
import { ViewOption } from "../components/ReportViewToggle";
import { GetReportsResponse, dataService } from "../services/data.service";
import { ReportStatus } from "../types/report";

export interface FilterOptions {
  textSearch: string;
  userGroupId: string | GroupOption;
  viewOption: ViewOption;
  sortOption: SortOption;
}

type SearchResults = Record<ViewOption, GetReportsResponse>;

interface ReportsState {
  status: "pending" | "succeeded" | "failed";
  rawTextSearch: string;
  filterOptions: FilterOptions;
  searchResults: SearchResults;
}

const initialState: ReportsState = {
  status: "pending",
  rawTextSearch: "",
  filterOptions: {
    textSearch: "",
    userGroupId: GroupOption.All,
    viewOption: ViewOption.Inbox,
    sortOption: SortOption.Priority,
  },
  searchResults: {
    [ViewOption.Inbox]: {
      page: 1,
      pageSize: 100,
      totalPages: 0,
      totalReports: 0,
      reports: [],
    },
    [ViewOption.Archive]: {
      page: 1,
      pageSize: 100,
      totalPages: 0,
      totalReports: 0,
      reports: [],
    },
  },
};

export const fetchAllReports = createAsyncThunk<
  SearchResults,
  { showSpinner: boolean },
  { state: RootState }
>("reports/fetchAllReports", async (args, { getState, rejectWithValue }) => {
  const { userGroupId } = getState().reports.filterOptions;
  const [{ data: Inbox }, { data: Archive }] = await Promise.all([
    dataService.getReports(userGroupId, ReportStatus.Inbox),
    dataService.getReports(userGroupId, ReportStatus.Archive),
  ]);
  if (Inbox && Archive) {
    return { Inbox, Archive };
  }
  return rejectWithValue(true);
});

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetReportsState: () => {
      return initialState;
    },
    updateRawTextSearch: (state, action: PayloadAction<string>) => {
      state.rawTextSearch = action.payload;
    },
    updateFilterOptions: (state, action: PayloadAction<Partial<FilterOptions>>) => {
      Object.assign(state.filterOptions, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllReports.pending, (state, action) => {
        if (action.meta.arg.showSpinner) {
          state.status = "pending";
        }
      })
      .addCase(
        fetchAllReports.fulfilled,
        (state, action: PayloadAction<SearchResults>) => {
          state.status = "succeeded";
          state.searchResults = action.payload;
        }
      )
      .addCase(fetchAllReports.rejected, (state) => {
        state.status = "failed";
        state.searchResults = initialState.searchResults;
      });
  },
});

// Actions
export const { resetReportsState, updateRawTextSearch, updateFilterOptions } =
  reportsSlice.actions;

export default reportsSlice.reducer;
