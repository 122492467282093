import { Authenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";

import components from "../components/authenticator/Components";
import formFields from "../components/authenticator/FormFields";
import { CYTED_HEALTH } from "../helpers/strings";

const Login = (): JSX.Element => {
  useEffect(() => {
    document.title = `Sign in | ${CYTED_HEALTH}`;
  }, []);
  return (
    <Authenticator
      hideSignUp
      className="mt-6"
      components={components}
      formFields={formFields}
    />
  );
};

export default Login;
