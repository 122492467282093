import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { dataService } from "../services/data.service";
import { AppDispatch } from "../store";
import { fetchAllReports } from "../store/reportsSlice";
import { ReportStatus } from "../types/report";
import { ViewOption } from "./ReportViewToggle";

export const TEST_ID_MOVE_REPORTS_BUTTON = "MoveReportsButton";
export const TEST_ID_SELECTED_REPORTS_CHECKBOX = "SelectedReportsCheckbox";
export const TEST_ID_SELECTED_REPORTS_CHECKBOX_LABEL = "SelectedReportsCheckboxLabel";

interface ReportListActionsProps {
  currentView: ViewOption;
  selectedReports: string[];
  clearSelectedReports: () => void;
}

const ReportListActions = ({
  currentView,
  selectedReports,
  clearSelectedReports,
}: ReportListActionsProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const targetView =
    currentView === ViewOption.Inbox ? ReportStatus.Archive : ReportStatus.Inbox;
  const selectedReportsCount = selectedReports.length;

  const checkboxLabel = `${selectedReports.length} selected`;
  const buttonLabel = `Move to ${targetView}`;
  const successMessage = `${selectedReportsCount} ${selectedReportsCount === 1 ? "report" : "reports"} moved to ${targetView}`;
  const errorMessage = `Error moving ${selectedReportsCount === 1 ? "report" : "reports"} to ${targetView}`;

  const handleClick = async (): Promise<void> => {
    setBusy(true);
    const response = await dataService.moveReports(selectedReports, targetView);
    if (response.data) {
      await dispatch(fetchAllReports({ showSpinner: false }));
      enqueueSnackbar(successMessage, { variant: "success" });
      clearSelectedReports();
    } else {
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
    setBusy(false);
  };

  return (
    <div className="is-flex is-align-items-center">
      <label
        className="checkbox has-text-weight-semibold ml-1 mr-4"
        data-testid={TEST_ID_SELECTED_REPORTS_CHECKBOX_LABEL}
      >
        <input
          type="checkbox"
          checked
          className="mr-3"
          onChange={clearSelectedReports}
          data-testid={TEST_ID_SELECTED_REPORTS_CHECKBOX}
        />
        {checkboxLabel}
      </label>
      <button
        type="button"
        className={classNames("button is-small is-rounded", {
          "is-loading": busy,
        })}
        onClick={handleClick}
        data-testid={TEST_ID_MOVE_REPORTS_BUTTON}
      >
        {buttonLabel}
      </button>
    </div>
  );
};

export default ReportListActions;
