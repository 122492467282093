import { HighlightMatch } from "@aws-amplify/ui-react";
import { format } from "date-fns";
import React from "react";

import { Report } from "../../types/report";
import DotSeparator from "./DotSeparator";

export const TEST_ID_REPORT_CARD_PATIENT_DISPLAY_NAME = "ReportCardPatientDisplayName";
export const TEST_ID_REPORT_CARD_PATIENT_SEX = "ReportCardPatientSex";
export const TEST_ID_REPORT_CARD_PATIENT_DATE_OF_BIRTH = "ReportCardPatientDateOfBirth";
export const TEST_ID_REPORT_CARD_PATIENT_IDENTIFIERS = "ReportCardPatientIdentifiers";

export interface PatientDetailsProps {
  report: Report;
  currentSearch: string;
  patientDisplayName: string;
}

const PatientDetails = ({
  report: {
    reportId,
    patientSex,
    patientDateOfBirth,
    patientDateOfBirthIsYearOnly,
    patientIdentifier,
    patientIdentifierAlternative,
  },
  currentSearch,
  patientDisplayName,
}: PatientDetailsProps): JSX.Element => {
  const patientDisplayDateOfBirth: string = format(
    patientDateOfBirth,
    patientDateOfBirthIsYearOnly ? "yyyy" : "d MMM yyyy"
  );

  const hasPatientIdentifier: boolean = !!patientIdentifier.length;
  const hasAlternativePatientIdentifier: boolean = !!patientIdentifierAlternative.length;

  return (
    <>
      <h5
        className="title is-5 mb-1"
        data-testid={TEST_ID_REPORT_CARD_PATIENT_DISPLAY_NAME + reportId}
      >
        <HighlightMatch query={currentSearch}>{patientDisplayName}</HighlightMatch>
      </h5>
      <p className="is-size-7">
        <span data-testid={TEST_ID_REPORT_CARD_PATIENT_SEX + reportId}>{patientSex}</span>
        <DotSeparator />
        <span data-testid={TEST_ID_REPORT_CARD_PATIENT_DATE_OF_BIRTH + reportId}>
          {patientDisplayDateOfBirth}
        </span>
      </p>
      {(hasPatientIdentifier || hasAlternativePatientIdentifier) && (
        <p
          className="is-size-7"
          data-testid={TEST_ID_REPORT_CARD_PATIENT_IDENTIFIERS + reportId}
        >
          {hasPatientIdentifier && (
            <HighlightMatch query={currentSearch}>{patientIdentifier}</HighlightMatch>
          )}
          {hasPatientIdentifier && hasAlternativePatientIdentifier && <DotSeparator />}
          {hasAlternativePatientIdentifier && (
            <HighlightMatch query={currentSearch}>
              {patientIdentifierAlternative}
            </HighlightMatch>
          )}
        </p>
      )}
    </>
  );
};

export default PatientDetails;
