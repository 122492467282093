import { faFile } from "@fortawesome/free-regular-svg-icons";
import { IconDefinition, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEvent } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../store";
import { fetchAllReports, updateFilterOptions } from "../store/reportsSlice";
import { ResultCounts } from "../types/report";
import { ViewOption } from "./ReportViewToggle";

export const TEST_ID_NO_RESULTS_HEADING = "NoResultsHeading";
export const TEST_ID_NO_RESULTS_OTHER_VIEW_LINK = "NoResultsOtherViewLink";
export const TEST_ID_NO_RESULTS_ICON = "NoResultsIcon";
export const TEST_ID_NO_SEARCH_RESULTS_GUIDANCE = "NoSearchResultsGuidance";

export interface NoResultsProps {
  currentView: ViewOption;
  currentSearch: string;
  filteredResultCounts: ResultCounts;
}

const NoResults = ({
  currentView,
  currentSearch,
  filteredResultCounts,
}: NoResultsProps): JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const otherViewOption: ViewOption =
    currentView === ViewOption.Inbox ? ViewOption.Archive : ViewOption.Inbox;
  const otherViewResultsCount: number = filteredResultCounts[otherViewOption];
  const resultsInOtherView: boolean = otherViewResultsCount > 0;
  const otherViewLinkText: string = `View ${otherViewResultsCount} ${otherViewResultsCount === 1 ? "result" : "results"} in ${otherViewOption}`;

  const handleClick = (e: MouseEvent): void => {
    e.preventDefault();
    dispatch(updateFilterOptions({ viewOption: otherViewOption }));
    dispatch(fetchAllReports({ showSpinner: true }));
  };

  const icon: IconDefinition = currentSearch ? faMagnifyingGlass : faFile;

  const noResultsHeading: string = currentSearch
    ? `No results found for "${currentSearch}"` +
      (resultsInOtherView ? ` in ${currentView}` : "")
    : `${currentView} is empty`;

  return (
    <div className="box content has-text-centered p-6">
      <FontAwesomeIcon
        icon={icon}
        size="2x"
        className="mb-5"
        data-testid={TEST_ID_NO_RESULTS_ICON}
      />
      {resultsInOtherView && (
        <p>
          <a
            href=""
            role="button"
            className="is-underlined is-size-5 has-text-weight-semibold"
            onClick={handleClick}
            data-testid={TEST_ID_NO_RESULTS_OTHER_VIEW_LINK}
          >
            {otherViewLinkText}
          </a>
        </p>
      )}
      <h4
        className={resultsInOtherView ? "is-size-6" : "is-size-5"}
        data-testid={TEST_ID_NO_RESULTS_HEADING}
      >
        {noResultsHeading}
      </h4>
      {currentSearch && (
        <p
          style={{ margin: "0 auto", maxWidth: "24rem" }}
          data-testid={TEST_ID_NO_SEARCH_RESULTS_GUIDANCE}
        >
          Sorry, we couldn’t find any results that match your search. Make sure your
          search is spelled correctly, or try another search term.
        </p>
      )}
      {!currentSearch && otherViewResultsCount === 0 && <p>No results</p>}
    </div>
  );
};

export default NoResults;
