import React from "react";

import { ViewOption } from "./ReportViewToggle";

interface ReportListHeadingProps {
  currentView: ViewOption;
  hideHeading: boolean;
}

const ReportListHeading = ({
  currentView,
  hideHeading,
}: ReportListHeadingProps): JSX.Element => {
  return (
    <h3 className={`title is-4 ${hideHeading ? "is-sr-only" : ""}`}>{currentView}</h3>
  );
};

export default ReportListHeading;
