import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const TEST_ID_RESULTS_ERROR_MESSAGE = "ResultsErrorMessage";

const ResultsError = (): JSX.Element => {
  return (
    <div
      className="box content has-text-centered p-6"
      data-testid={TEST_ID_RESULTS_ERROR_MESSAGE}
    >
      <FontAwesomeIcon icon={faCircleXmark} size="2x" className="mb-5" />
      <h4>Error</h4>
      <p>Unable to load results. Try refreshing the page.</p>
    </div>
  );
};

export default ResultsError;
