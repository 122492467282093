import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchMFAPreference, fetchUserAttributes } from "aws-amplify/auth";
import { sortBy } from "lodash";

import { RootState } from ".";
import { CustomError, dataService } from "../services/data.service";
import { UserGroupWithPolicy } from "../types/userGroup";

export type Environment =
  | "local-development"
  | "staging"
  | "pre-production"
  | "production";

export interface AuthState {
  status: "pending" | "succeeded" | "failed";
  error?: CustomError;
  currentUser: UserData;
  userGroups: UserGroupWithPolicy[];
  environment: Environment;
}

interface UserData {
  firstName?: string;
  lastName?: string;
  isMfaEnabled?: boolean;
}

export const fetchUserGroups = createAsyncThunk<
  UserGroupWithPolicy[],
  void,
  { rejectValue: CustomError }
>("metadata/fetchUserGroups", async (_, { rejectWithValue }) => {
  const response = await dataService.getUserGroups();
  return response.data ?? rejectWithValue(response.error);
});

export const fetchUserData = createAsyncThunk<UserData, void, { rejectValue: unknown }>(
  "auth/fetchUserData",
  async (_, { rejectWithValue }) => {
    try {
      const [{ given_name, family_name }, { enabled }] = await Promise.all([
        fetchUserAttributes(),
        fetchMFAPreference(),
      ]);
      const userData: UserData = {
        firstName: given_name,
        lastName: family_name,
        isMfaEnabled: enabled?.includes("TOTP") ?? false,
      };
      return userData;
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Error fetching user data"
      );
    }
  }
);

const initialState: AuthState = {
  status: "pending",
  error: undefined,
  currentUser: {},
  userGroups: [],
  environment: import.meta.env.VITE_APP_ENVIRONMENT ?? "local-development",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserGroups.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        fetchUserGroups.fulfilled,
        (state, action: PayloadAction<UserGroupWithPolicy[]>) => {
          state.status = "succeeded";
          state.userGroups = action.payload;
        }
      )
      .addCase(fetchUserGroups.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchUserData.fulfilled, (state, action: PayloadAction<UserData>) => {
        state.currentUser = action.payload;
      });
  },
});

// Actions
export const { resetAuthState } = authSlice.actions;

// Selectors
export const selectEnvironment = ({ auth }: RootState) => auth.environment;
export const selectCurrentUser = ({ auth }: RootState) => auth.currentUser;
export const selectUserGroups = createSelector(
  ({ auth }: RootState) => auth.userGroups,
  (userGroups) => sortBy(userGroups, "name")
);

// Security
export const selectIsMfaRequiredByGroupPolicy = ({ auth }: RootState) => {
  return auth.userGroups.some(({ mfaRequired }) => mfaRequired);
};
export const selectForceMfaEnrolment = (state: RootState) => {
  const isMfaEnabledForCurrentUser = state.auth.currentUser.isMfaEnabled;
  const isMfaRequiredByGroupPolicy = selectIsMfaRequiredByGroupPolicy(state);
  return isMfaRequiredByGroupPolicy && isMfaEnabledForCurrentUser === false;
};

export default authSlice.reducer;
