import { SnackbarKey, useSnackbar } from "notistack";
import React from "react";

interface DismissSnackbarButtonProps {
  snackbarKey: SnackbarKey;
}

const DismissSnackbarButton = ({ snackbarKey }: DismissSnackbarButtonProps) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <button
      className="button is-rounded is-small m-1"
      onClick={() => closeSnackbar(snackbarKey)}
    >
      Dismiss
    </button>
  );
};

export default DismissSnackbarButton;
