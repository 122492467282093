import classNames from "classnames";
import React, { ChangeEvent } from "react";

import { IssueType, ManagementPriority, Report } from "../types/report";
import PatientDetails from "./report/PatientDetails";
import ReportActions from "./report/ReportActions";
import ReportCardHeader from "./report/ReportCardHeader";
import ReportCheckbox from "./report/ReportCheckbox";
import ReportDetails from "./report/ReportDetails";
import SampleDetails from "./report/SampleDetails";

export const TEST_ID_REPORT_CARD = "ReportCard";
export const TEST_ID_REPORT_CARD_HEADER = "ReportCardHeader";
export const TEST_ID_REPORT_CARD_CONTENT = "ReportCardContent";

export interface ReportCardProps {
  report: Report;
  currentSearch: string;
  isSelected: boolean;
  updateSelectedReports: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ReportCard = ({
  report,
  currentSearch,
  isSelected,
  updateSelectedReports,
}: ReportCardProps): JSX.Element => {
  const { reportId, patientFirstName, patientSurname, managementPriority, issueType } =
    report;
  const { URGENT, INADEQUATE, ROUTINE, NORMAL } = ManagementPriority;
  const { SUPERSEDED, REISSUED, AMENDED, PENDING } = IssueType;

  // Name
  const patientDisplayName: string = `${patientFirstName} ${patientSurname}`;

  // Priority
  const isUrgent: boolean = managementPriority === URGENT;
  const isInadequate: boolean = managementPriority === INADEQUATE;
  const isNonUrgent: boolean = managementPriority === ROUTINE;
  const isNormal: boolean = managementPriority === NORMAL;

  // Issue type
  const isPending: boolean = issueType === PENDING;
  const isSuperseded: boolean = issueType === SUPERSEDED;
  const isInactive: boolean = isPending || isSuperseded;
  const isReissued: boolean = issueType === REISSUED || issueType == AMENDED;

  return (
    <li
      className={classNames("cyt-report-card", {
        "cyt-report-card--urgent": isUrgent,
        "cyt-report-card--inadequate": isInadequate,
        "cyt-report-card--nonurgent": isNonUrgent,
        "cyt-report-card--normal": isNormal,
        "cyt-report-card--inactive": isInactive,
        "cyt-report-card--selected": isSelected,
      })}
      data-testid={TEST_ID_REPORT_CARD + reportId}
    >
      <ReportCardHeader report={report} />
      <div
        className="cyt-report-card__content"
        data-testid={TEST_ID_REPORT_CARD_CONTENT + reportId}
      >
        <div className="columns">
          <div className="column is-narrow p-0">
            <ReportCheckbox
              reportId={reportId}
              isInactive={isInactive}
              isSelected={isSelected}
              patientDisplayName={patientDisplayName}
              updateSelectedReports={updateSelectedReports}
            />
          </div>
          <div className="column">
            <PatientDetails
              report={report}
              currentSearch={currentSearch}
              patientDisplayName={patientDisplayName}
            />
          </div>
          <div className="column is-one-fifth">
            <SampleDetails report={report} currentSearch={currentSearch} />
          </div>
          <div className="column is-one-fifth">
            <ReportDetails
              report={report}
              isInactive={isInactive}
              isReissued={isReissued}
            />
          </div>
          <div className="column">{!isInactive && <ReportActions report={report} />}</div>
        </div>
      </div>
    </li>
  );
};

export default ReportCard;
