import { IssueType, ManagementPriority } from "../types/report";

type ManagementPriorityLabel = { group: string; card: string };

export const CYTED_HEALTH = "Cyted Health";

export const NOT_PROVIDED = "Not provided";

export const managementPriorityLabel: Record<
  ManagementPriority,
  ManagementPriorityLabel
> = {
  [ManagementPriority.URGENT]: {
    group: "Urgent",
    card: "Urgent",
  },
  [ManagementPriority.INADEQUATE]: {
    group: "Inadequate",
    card: "Inadequate sample",
  },
  [ManagementPriority.ROUTINE]: {
    group: "Non-urgent",
    card: "Non-urgent",
  },
  [ManagementPriority.NORMAL]: {
    group: "Normal",
    card: "Normal",
  },
  [ManagementPriority.NULL]: {
    group: "Pending",
    card: "",
  },
};

export const issueTypeLabel: Record<IssueType, string> = {
  [IssueType.AUTHORISED]: "Authorised",
  [IssueType.REISSUED]: "Reissued",
  [IssueType.AMENDED]: "Amended",
  [IssueType.SUPERSEDED]: "Superseded",
  [IssueType.PENDING]: "Pending",
};
