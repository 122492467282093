import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../store";
import { updateFilterOptions, updateRawTextSearch } from "../store/reportsSlice";
import { Report } from "../types/report";

export const TEST_ID_REPORT_SEARCH_INPUT = "ReportSearchInput";

type SearchFilter = (report: Report) => boolean;

export const getSearchFilter = (searchValue: string): SearchFilter => {
  return ({
    patientFirstName,
    patientSurname,
    patientIdentifier,
    patientIdentifierAlternative,
    recordNumber,
  }: Report): boolean => {
    return [
      `${patientFirstName} ${patientSurname}`,
      patientIdentifier,
      patientIdentifierAlternative,
      recordNumber,
    ].some((value) => value.toLowerCase().includes(searchValue.toLowerCase()));
  };
};

const ReportSearch = (): JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const { rawTextSearch } = useSelector((state: RootState) => state.reports);

  // If the search field has any numbers in it (e.g. searches for
  // NHS numbers), remove all whitespace. Otherwise, (e.g. searches
  // for names) only trim leading and trailing whitespace.
  const getNormalisedTextSearch = (value: string): string => {
    return /\d/.test(value) ? value.replace(/\s/g, "") : value.trim();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const textSearch = getNormalisedTextSearch(value);
    dispatch(updateRawTextSearch(value));
    dispatch(updateFilterOptions({ textSearch }));
  };

  return (
    <div className="cyt-report-search">
      <label className="label" htmlFor="search">
        Search
      </label>
      <div className="control has-icons-left">
        <input
          type="search"
          id="search"
          className="input is-rounded"
          placeholder="Search"
          aria-describedby="report-list-search-hint"
          value={rawTextSearch}
          onChange={handleChange}
          data-testid={TEST_ID_REPORT_SEARCH_INPUT}
        />
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
      </div>
      <p className="help" id="report-list-search-hint">
        Search by name, number or ID
      </p>
    </div>
  );
};

export default ReportSearch;
