import { faCheck, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateMFAPreference } from "aws-amplify/auth";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { AppDispatch } from "../../store";
import { fetchUserData, selectIsMfaRequiredByGroupPolicy } from "../../store/authSlice";

export const TEST_ID_DISABLE_MFA_BUTTON = "DisableMultiFactorAuthenticationButton";

export const MFA_ENABLED_MESSAGE =
  "Multi-factor authentication is enabled for your account.";
export const DISABLE_MFA_BUTTON_TEXT = "Disable multi-factor authentication";
export const DISABLE_MFA_CONFIRM_MESSAGE =
  "Disabling multi-factor authentication will remove an extra layer of security from your account. Are you sure?";
export const DISABLE_MFA_SUCCESS_MESSAGE = "Disabled multi-factor authentication";
export const DISABLE_MFA_ERROR_MESSAGE = "Sorry, that didn't work. Try again later.";
export const HOME_PAGE_LINK_TEXT = "Go to home page";

const MFAComplete = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const isMfaRequiredByGroupPolicy = useSelector(selectIsMfaRequiredByGroupPolicy);

  // Local state
  const [busy, setBusy] = useState<boolean>(false);

  const handleDisableMfaClick = async (): Promise<void> => {
    if (isMfaRequiredByGroupPolicy) return;
    const proceed = window.confirm(DISABLE_MFA_CONFIRM_MESSAGE);
    if (proceed) {
      setBusy(true);
      try {
        await updateMFAPreference({ sms: "DISABLED", totp: "DISABLED" });
        await dispatch(fetchUserData());
        enqueueSnackbar(DISABLE_MFA_SUCCESS_MESSAGE, { variant: "success" });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(DISABLE_MFA_ERROR_MESSAGE, { variant: "error" });
      }
      setBusy(false);
    }
  };

  return (
    <div className="content">
      <p>
        {MFA_ENABLED_MESSAGE}
        <FontAwesomeIcon icon={faCheck} size="lg" color="green" className="ml-2" />
      </p>
      {!isMfaRequiredByGroupPolicy && (
        <p>
          <button
            type="button"
            className={classNames("button is-small is-warning is-rounded", {
              "is-loading": busy,
            })}
            onClick={handleDisableMfaClick}
            data-testid={TEST_ID_DISABLE_MFA_BUTTON}
          >
            <FontAwesomeIcon icon={faUnlock} className="mr-2" />
            {DISABLE_MFA_BUTTON_TEXT}
          </button>
        </p>
      )}
      <p>
        <Link to="/">&laquo; {HOME_PAGE_LINK_TEXT}</Link>
      </p>
    </div>
  );
};

export default MFAComplete;
