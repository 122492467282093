import { fetchAuthSession } from "@aws-amplify/auth";
import * as Sentry from "@sentry/browser";
import axios, { AxiosInstance, AxiosResponse, isAxiosError } from "axios";
import { isEmpty } from "lodash";

import { NOT_PROVIDED } from "../helpers/strings";
import { APIReport, ManagementPriority, Report, ReportStatus } from "../types/report";
import { UserGroupWithPolicy } from "../types/userGroup";

export type GetReportsAPIResponse = Omit<GetReportsResponse, "reports"> & {
  reports: APIReport[];
};

export interface GetReportsResponse {
  page: number;
  pageSize: number;
  totalPages: number;
  totalReports: number;
  reports: Report[];
}

interface OkResult<T> {
  data: T;
  error: null;
}

interface ErrorResult {
  data: null;
  error: CustomError;
}

export type ApiResult<T> = OkResult<T> | ErrorResult;

export interface CustomError {
  error: string;
  msg: string;
  status?: number;
}

const transformReportFromApi = ({
  reportId,
  dateOfIssue,
  caseType,
  reportData: { managementPriority, patientIdentifier, ...rest },
}: APIReport): Report => {
  return {
    reportId,
    dateOfIssue,
    caseType,
    managementPriority: managementPriority ?? ManagementPriority.NULL,
    patientIdentifier: patientIdentifier === NOT_PROVIDED ? "" : patientIdentifier,
    ...rest,
  };
};

const getRestApi = (): AxiosInstance => {
  const restApi = axios.create({
    // Use Vite server proxy for /api requests in local dev
    baseURL: import.meta.env.VITE_APP_BACKEND_URL ?? "/api",
  });
  restApi.interceptors.request.use((config) => {
    // fetchAuthSession() refreshes tokens with Cognito if needed
    return fetchAuthSession()
      .then((session) => {
        config.headers.Authorization = `Bearer ${session.tokens?.idToken?.toString()}`;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  restApi.interceptors.response.use(
    (response) => response,
    (error) => {
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  );
  return restApi;
};

const getUserGroups = async () => {
  try {
    const response = await getRestApi().get("/groups");
    const data = parseResponse(response);
    const userGroups = data.groups as UserGroupWithPolicy[];
    if (isEmpty(userGroups)) {
      throw new RangeError("User is not assigned to any groups");
    }
    return okResult(userGroups);
  } catch (error: unknown) {
    console.error(error);
    Sentry.captureException(error);
    return errorResult(error);
  }
};

const getReports = async (
  userGroupId: string,
  status: ReportStatus,
  page: number = 1
) => {
  try {
    const response = await getRestApi().get(`/reports/${userGroupId}`, {
      params: { status, page },
    });
    const { reports, ...rest }: GetReportsAPIResponse = parseResponse(response);
    return okResult({
      reports: reports.map(transformReportFromApi),
      ...rest,
    } as GetReportsResponse);
  } catch (error: unknown) {
    console.error(error);
    Sentry.captureException(error);
    return errorResult(error);
  }
};

const getReportDownload = async (reportId: string) => {
  try {
    const response = await getRestApi().get(`/report-download/${reportId}`, {
      responseType: "blob",
    });
    const objectUrl: string = window.URL.createObjectURL(
      new Blob([response.data], { type: "application/pdf" })
    );
    return okResult({ objectUrl });
  } catch (error: unknown) {
    console.error(error);
    Sentry.captureException(error);
    return errorResult(error);
  }
};

const moveReports = async (reportIds: string[], targetView: ReportStatus) => {
  try {
    const response = await getRestApi().put(`/transition-reports?status=${targetView}`, {
      reportIds,
    });
    const data = parseResponse(response);
    return okResult(data.ok as boolean);
  } catch (error: unknown) {
    console.error(error);
    Sentry.captureException(error);
    return errorResult(error);
  }
};

const parseResponse = (response: AxiosResponse) => {
  if (![200, 201].includes(response.status)) {
    throw Error(response.statusText);
  }
  if (!response.data) {
    return [];
  }
  let data = response.data;
  if (typeof data !== "object") {
    data = [];
  }
  return data;
};

const okResult = <T>(data: T): OkResult<T> => ({
  data,
  error: null,
});

const errorResult = (error: unknown): ErrorResult => ({
  data: null,
  error:
    isAxiosError(error) && error.response
      ? {
          // Try to get custom errors, fall back to Axios errors
          error: error.response.data.error ?? error.response.statusText,
          msg: error.response.data.msg ?? error.message,
          status: error.response.status ?? error.status,
        }
      : {
          error: "Error",
          msg: "",
          status: undefined,
        },
});

export const dataService = {
  getUserGroups,
  getReports,
  getReportDownload,
  moveReports,
};
