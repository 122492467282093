import React from "react";

import LoadingSpinner from "./LoadingSpinner";

interface LoadingBackdropProps {
  title?: string;
  subtitle?: string;
}

const LoadingBackdrop = ({ title, subtitle }: LoadingBackdropProps): JSX.Element => {
  return (
    <div className="cyt-loading-backdrop">
      <div className="cyt-loading-backdrop__body">
        <LoadingSpinner />
        <h1 className="title is-4 mt-5">{title ?? "Loading"}</h1>
        <h2 className="subtitle">{subtitle ?? "Just a moment..."}</h2>
      </div>
    </div>
  );
};

export default LoadingBackdrop;
