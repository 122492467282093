import { ViewOption } from "../components/ReportViewToggle";

export enum CaseType {
  REFLUX = "Reflux",
  SURVEILLANCE = "Barrett’s surveillance",
}

export enum ReportStatus {
  Inbox = "inbox",
  Archive = "archive",
}

export enum IssueType {
  AUTHORISED = "IssueTypeAuthorised",
  REISSUED = "IssueTypeReissued",
  AMENDED = "IssueTypeAmended",
  SUPERSEDED = "IssueTypeSuperseded",
  PENDING = "IssueTypePending",
}

// Expect real null values from API for pending reports
export type NullableManagementPriority = Exclude<
  ManagementPriority,
  ManagementPriority.NULL
> | null;

// Coerce them to NULL enum value for UI display logic
export enum ManagementPriority {
  URGENT = "ManagementPriorityUrgent",
  INADEQUATE = "ManagementPriorityInadequate",
  ROUTINE = "ManagementPriorityRoutine",
  NORMAL = "ManagementPriorityNormal",
  NULL = "ManagementPriorityNull",
}

export type APIReportData = Omit<
  Report,
  "reportId" | "dateOfIssue" | "caseType" | "managementPriority"
> & {
  managementPriority: NullableManagementPriority;
};

export interface APIReport {
  reportId: string;
  dateOfIssue: string | null;
  reportData: APIReportData;
  caseType: CaseType;
}

export interface Report {
  reportId: string;
  patientFirstName: string;
  patientSurname: string;
  patientInitials: string;
  patientSex: string;
  patientDateOfBirth: string;
  patientDateOfBirthIsYearOnly: boolean;
  patientIdentifier: string;
  patientIdentifierAlternative: string;
  labNumber: string;
  recordNumber: string;
  procedureDate: string;
  managementPriority: ManagementPriority;
  dateOfIssue: string | null;
  caseType: CaseType;
  issueType: IssueType;
}

export type ResultCounts = Record<ViewOption, number>;
