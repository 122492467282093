import classNames from "classnames";
import { format } from "date-fns";
import { useSnackbar } from "notistack";
import React, { MouseEvent, useState } from "react";

import { issueTypeLabel } from "../../helpers/strings";
import { dataService } from "../../services/data.service";
import { IssueType, Report } from "../../types/report";

export enum ReportCardButton {
  View = "ReportCardViewButton",
  Download = "ReportCardDownloadButton",
}

export const TEST_ID_REPORT_CARD_VIEW_BUTTON = ReportCardButton.View;
export const TEST_ID_REPORT_CARD_DOWNLOAD_BUTTON = ReportCardButton.Download;

export const REPORT_DOWNLOAD_ERROR_MESSAGE = "Unable to retrieve report";

interface ReportActionsProps {
  report: Report;
}

const ReportActions = ({
  report: {
    reportId,
    recordNumber,
    patientInitials,
    patientIdentifier,
    patientIdentifierAlternative,
    dateOfIssue,
    issueType,
  },
}: ReportActionsProps): JSX.Element => {
  const [busyButton, setBusyButton] = useState<ReportCardButton>();
  const { enqueueSnackbar } = useSnackbar();

  const { AMENDED, REISSUED } = IssueType;

  // File name segments for report PDF download
  const patientId = patientIdentifier || patientIdentifierAlternative;
  const reportDate = dateOfIssue ? format(dateOfIssue, "yyyyMMdd") : "";
  const issueTypeSuffix = [REISSUED, AMENDED].includes(issueType)
    ? `-${issueTypeLabel[issueType].toUpperCase()}`
    : "";

  // Concatenate file name segments, then remove all but alphanumeric characters and dashes
  const rawFileName = `${patientId}-${patientInitials}-${recordNumber}-${reportDate}${issueTypeSuffix}`;
  const pdfFileName = rawFileName.replace(/[^a-zA-Z0-9-]/g, "") + ".pdf";

  const handleClick = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
    const {
      name: button,
      dataset: { filename },
    } = e.target as HTMLButtonElement;
    setBusyButton(button as ReportCardButton);
    const response = await dataService.getReportDownload(reportId);
    if (response.data?.objectUrl) {
      const { objectUrl } = response.data;

      // View
      if (button === ReportCardButton.View) {
        const newTab = window.open(objectUrl, "_blank");
        newTab?.addEventListener("beforeunload", () => {
          URL.revokeObjectURL(objectUrl);
        });
      }

      // Download
      if (button === ReportCardButton.Download && filename) {
        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(objectUrl);
      }
    } else {
      enqueueSnackbar(REPORT_DOWNLOAD_ERROR_MESSAGE, { variant: "error" });
    }
    setBusyButton(undefined);
  };

  return (
    <div className="buttons are-small is-justify-content-flex-end">
      <button
        type="button"
        name={ReportCardButton.View}
        className={classNames({
          "button is-rounded is-light": true,
          "is-loading": busyButton === ReportCardButton.View,
        })}
        data-testid={TEST_ID_REPORT_CARD_VIEW_BUTTON + reportId}
        onClick={handleClick}
      >
        View <span className="is-sr-only">PDF report in new tab</span>
      </button>
      <button
        type="button"
        name={ReportCardButton.Download}
        className={classNames({
          "button is-rounded is-primary": true,
          "is-loading": busyButton === ReportCardButton.Download,
        })}
        data-filename={pdfFileName}
        data-testid={TEST_ID_REPORT_CARD_DOWNLOAD_BUTTON + reportId}
        onClick={handleClick}
      >
        Download <span className="is-sr-only">PDF report</span>
      </button>
    </div>
  );
};

export default ReportActions;
