import { Authenticator } from "@aws-amplify/ui-react";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import DismissSnackbarButton from "./components/common/DismissSnackbarButton";
import AccessControlGuard from "./routes/AccessControlGuard";
import Home from "./routes/Home";
import Root from "./routes/Root";
import Settings from "./routes/Settings";
import { store } from "./store";

Sentry.init({
  dsn: "https://56208d80d57dcf164a964cf9302161f6@o429717.ingest.sentry.io/4506852271915008",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api.*portal\.cytedhealth\.com/],
  environment: import.meta.env.VITE_APP_ENVIRONMENT ?? "local-development",
});

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_APP_USER_POOL_APP_CLIENT_ID,
    },
  },
});

// Override AWS Amplify microcopy
I18n.setLanguage("en");
I18n.putVocabularies({
  en: {
    "User is disabled.": "User is deactivated.",
    "Temporary password has expired and must be reset by an administrator.":
      "Your temporary password has expired. Please contact us at service@cytedhealth.com to reset it.",
  },
});

const snackbarProps: SnackbarProviderProps = {
  maxSnack: 1,
  iconVariant: {
    success: <FontAwesomeIcon className="mr-3" icon={faCheck} />,
    error: <FontAwesomeIcon className="mr-3" icon={faXmark} />,
  },
  action: (key) => <DismissSnackbarButton snackbarKey={key} />,
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export enum UrlRoutes {
  SETTINGS = "settings",
}

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: (
      <AccessControlGuard>
        <Root />
      </AccessControlGuard>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: UrlRoutes.SETTINGS,
        element: <Settings />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Authenticator.Provider>
      <SnackbarProvider {...snackbarProps}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </SnackbarProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);
