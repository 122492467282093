import classNames from "classnames";
import React, { ChangeEvent } from "react";

export const TEST_ID_REPORT_CARD_CHECKBOX_INPUT = "ReportCardCheckbox";
export const TEST_ID_REPORT_CARD_CHECKBOX_LABEL = "ReportCardCheckboxLabel";

export interface ReportCheckboxProps {
  reportId: string;
  isInactive: boolean;
  isSelected: boolean;
  patientDisplayName: string;
  updateSelectedReports: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ReportCheckbox = ({
  reportId,
  isInactive,
  isSelected,
  patientDisplayName,
  updateSelectedReports,
}: ReportCheckboxProps): JSX.Element => {
  return (
    <label
      style={{ height: "100%" }}
      className={classNames("is-block p-3", {
        "is-clickable": !isInactive,
        "is-invisible": isInactive,
      })}
      data-testid={TEST_ID_REPORT_CARD_CHECKBOX_LABEL + reportId}
    >
      <input
        type="checkbox"
        value={reportId}
        checked={isSelected}
        disabled={isInactive}
        className="is-clickable"
        aria-label={`Select report for ${patientDisplayName}`}
        data-testid={TEST_ID_REPORT_CARD_CHECKBOX_INPUT + reportId}
        onChange={updateSelectedReports}
      />
    </label>
  );
};

export default ReportCheckbox;
