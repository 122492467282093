import { faRepeat } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React from "react";

import { issueTypeLabel } from "../../helpers/strings";
import { Report } from "../../types/report";

export const TEST_ID_REPORT_CARD_ISSUE_TYPE = "ReportCardIssueType";
export const TEST_ID_REPORT_CARD_ISSUE_TYPE_ICON = "ReportCardIssueTypeIcon";
export const TEST_ID_REPORT_CARD_ISSUE_DATE = "ReportCardIssueDate";

export interface ReportDetailsProps {
  report: Report;
  isInactive: boolean;
  isReissued: boolean;
}

const ReportDetails = ({
  report: { reportId, dateOfIssue, issueType },
  isInactive,
  isReissued,
}: ReportDetailsProps): JSX.Element => {
  const issueDate = dateOfIssue ? format(dateOfIssue, "d MMM yyyy") : null;

  return (
    <>
      <h5
        className="title is-5 mb-1"
        data-testid={TEST_ID_REPORT_CARD_ISSUE_TYPE + reportId}
      >
        {isReissued && (
          <FontAwesomeIcon
            className="mr-2"
            size="xs"
            icon={faRepeat}
            data-testid={TEST_ID_REPORT_CARD_ISSUE_TYPE_ICON}
          />
        )}
        <span>{issueTypeLabel[issueType]}</span>
      </h5>
      {!isInactive && issueDate && (
        <p className="is-size-7" data-testid={TEST_ID_REPORT_CARD_ISSUE_DATE + reportId}>
          {issueDate}
        </p>
      )}
    </>
  );
};

export default ReportDetails;
