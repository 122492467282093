import { HighlightMatch } from "@aws-amplify/ui-react";
import { format } from "date-fns";
import React from "react";

import { Report } from "../../types/report";

export const TEST_ID_REPORT_CARD_KIT_ID = "ReportCardKitId";
export const TEST_ID_REPORT_CARD_PROCEDURE_DATE = "ReportCardProcedureDate";

export interface SampleDetailsProps {
  report: Report;
  currentSearch: string;
}

const SampleDetails = ({
  report: { reportId, recordNumber, procedureDate },
  currentSearch,
}: SampleDetailsProps): JSX.Element => {
  const displayProcedureDate: string = format(procedureDate, "d MMM yyyy");

  return (
    <>
      <h5 className="title is-5 mb-1" data-testid={TEST_ID_REPORT_CARD_KIT_ID + reportId}>
        <HighlightMatch query={currentSearch}>{recordNumber}</HighlightMatch>
      </h5>
      <p
        className="is-size-7"
        data-testid={TEST_ID_REPORT_CARD_PROCEDURE_DATE + reportId}
      >
        {displayProcedureDate}
      </p>
    </>
  );
};

export default SampleDetails;
